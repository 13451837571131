import classnames from "classnames"
import React from "react"
import { useRedemptionData } from "../../../../Context/redemption"
import { Button } from "../../.."
import styles from "./styles.module.scss"
import ColorPicker from "../../../Common/ColorPicker"

function Step2({ onContinue }) {
  const { redemptionPageData, setRedemptionPageData } = useRedemptionData()
  const { HeaderImageURL, MainColor, AccentColor, Caption } = redemptionPageData

  const handleUpdateMainColor = color => {
    setRedemptionPageData({
      ...redemptionPageData,
      MainColor: color,
    })
  }

  const handleUpdateAccentColor = color => {
    setRedemptionPageData({
      ...redemptionPageData,
      AccentColor: color,
    })
  }

  const handleUpdateCaption = e => {
    setRedemptionPageData({
      ...redemptionPageData,
      Caption: e.target.value,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>Choose your color palette</div>
          <div className={styles.colorPickerButtons}>
          <div className={styles.row} id="colorSelBtn">
            <ColorPicker
              className={styles.colorPicker}
              title="Main Color"
              backColor={MainColor}
              foreColor={AccentColor}
              onChange={handleUpdateMainColor}
            />
            <div className={styles.colorCode}>{MainColor}</div>
          </div>
          <div className={styles.row} id="colorSelBtn">
            <ColorPicker
              title="Accent Color"
              backColor={AccentColor}
              foreColor={MainColor}
              onChange={handleUpdateAccentColor}
            />
            <div className={styles.colorCode}>{AccentColor}</div>
          </div></div>
          <div className={styles.title}>Add a custom caption in the header</div>
          <input
            className={styles.inputCaption}
            value={Caption || ""}
            onChange={handleUpdateCaption}
            placeholder="Enter your caption here or leave blank"
          />
        </div>
        <div className={styles.preview}>
          <div className={styles.section}>
            <div className={styles.subTitle}>Page Preview</div>
            <div className={styles.previewWrapper}>
            <div className={styles.header}>
          {HeaderImageURL && (
            <img src={HeaderImageURL} alt="Custom Logo" />
          )}
          <div
            className={classnames(styles.lblCaption, {
              [styles.center]: !HeaderImageURL,
            })}
            style={{ color: MainColor }}>
            {Caption}
          </div>
        </div>
              <div
                className={styles.content}
                style={{ backgroundColor: MainColor }}>
                <div className={styles.redeemForm}>
                  <div
                    className={styles.code}
                    style={{ borderColor: MainColor, color: MainColor }}>
                    test123
                  </div>
                  <div className={styles.lblCode}>Change Code</div>
                  <div
                    className={styles.email}
                    style={{ borderColor: MainColor, color: MainColor }}>
                    Email Address
                  </div>
                  <div className={styles.tip}>
                    Optional: To receive a confirmation
                  </div>
                  <div className={styles.terms}>
                    <div
                      className={styles.checkbox}
                      style={{
                        borderColor: AccentColor,
                        color: AccentColor,
                      }}></div>
                    <div className={styles.lblTerms}>
                      I affirm that I am the gift recipient or that I received
                      this code in a rewards program by me redeeming my points.
                      View our <span>Terms and Conditions</span>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={styles.button}
                  style={{ backgroundColor: AccentColor }}>
                  Continue
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button className={styles.btnContinue} onClick={onContinue}>
        Continue
      </Button>
    </div>
  )
}

export default Step2
