// import React, { useState, useEffect } from "react"
// import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, NavLink, UncontrolledDropdown } from "reactstrap"
// import { Link } from "react-router-dom"
// import { toast } from "react-toastify"
// import MainMenu from "../../../Common/MainMenu"
// import { useUserData } from "../../../../Context/user"
// import { useCartData } from "../../../../Context/cart"
// import { GetRedeemCodeInfo, RedeemCodes } from "../../../../Code/Data"
// import LoginModal from "../../../Common/LoginModal"
// import SuccessRedemption from "../../../RedeemCodes/Success"
// import { useCommonRedemptionEffect } from "../../Hooks/commonRedemptionEffect"
// import styles from "./styles.module.scss"
// import { useAppData } from "../../../../Context/appData"
//
// export default function RedBarFullSize(props) {
//   const [redeemCode, setRedeemCode] = useState("")
//   const [submitted, setSubmitted] = useState()
//   const [openLoginModal, setOpenLoginModal] = useState()
//   const [directRedemption, setDirectRedemption] = useState(false)
//   const [redeemInfo, setRedeemInfo] = useState("")
//   const [codeValid, setCodeValid] = useState(false)
//   const { fullCharityList } = useAppData()
//   const [showSuccess, setShowSuccess] = useState(false)
//   const [charityName, setCharityName] = useState("")
//   const { loggedInUser, logout, setUser } = useUserData()
//   const { loading, cart } = useCartData()
//   const { style, children } = props
//   let timerId
//
//   useCommonRedemptionEffect(redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted)
//
//   useEffect(() => {
//     async function handleDirectRedemption() {
//       if (directRedemption) {
//         await RedeemDirectlyToCharity()
//       }
//     }
//
//     handleDirectRedemption()
//   }, [directRedemption])
//
//   // const delayDebounceFn = setTimeout(() => {
//   //   fetchData()
//   //     .then((res) => {
//   //       // Handle invalid format
//   //       if (
//   //         res &&
//   //         res.ErrorMessage &&
//   //         !redeemCodeValidation(redeemCode)
//   //       ) {
//   //         setCodeValid(false)
//   //         toast.warn(
//   //           "The Redemption Code entered is not a valid CharityChoice Redemption Code.",
//   //           {
//   //             position: toast.POSITION.TOP_CENTER,
//   //             autoClose: 7000,
//   //           },
//   //         )
//   //         return
//   //       }
//   //
//   //       // Handle redeemed code
//   //       else if (res && res.RedeemInfo && res.RedeemInfo.HasRedeemed) {
//   //         setCodeValid(false)
//   //         toast.warn("The code has already been redeemed.", {
//   //           position: toast.POSITION.TOP_CENTER,
//   //           autoClose: 7000,
//   //         })
//   //         return
//   //       }
//   //       else if (res && !res.RedeemInfo.HasRedeemed) {
//   //         setCodeValid(true)
//   //         return
//   //       }
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error in setTimeout callback:", error)
//   //     })
//   // }, 800)
//   const handleChangeRedeemCode = ({ target }) => {
//     clearTimeout(timerId)
//     timerId = setTimeout(()=>{
//       setRedeemCode(target.value)
//     }, 1000)
//   }
//   const RedeemDirectlyToCharity = async () => {
//     const selectedCharity = fullCharityList.find(({ CharityId }) => CharityId === redeemInfo.CharityId)
//     const payload = {
//       RedeemCode: redeemCode,
//       Charities: selectedCharity ? [{ CharityId: selectedCharity.CharityId }] : [],
//       ShareRedeemerInfo: redeemInfo?.ShowDonor,
//     }
//     payload.Charities[0].Amount = +redeemInfo.AmountToRedeem
//     const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
//     if (Succeeded) {
//       setShowSuccess(true)
//     }
//     else {
//       toast.error(ErrorMessage)
//     }
//   }
//   const handleSuccessLogin = user => {
//     setOpenLoginModal(false)
//     setUser(user)
//   }
//
//   const handleRedeem = async () => {
//     if (!redeemCode) {
//       window.location = "/Redeem"
//     }
//     const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode)
//     setRedeemInfo(RedeemInfo)
//
//     // Handle direct charity redemption
//     if (RedeemInfo?.HasCharityId) {
//       setDirectRedemption(true)
//       return
//     }
//     setSubmitted(true)
//
//     if (!Succeeded) {
//       toast.error(ErrorMessage, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 7000,
//       })
//       return false
//     }
//     if (RedeemInfo.HasCustomRedemptionPage && !!RedeemInfo.RedemptionPageURL) {
//       window.location = RedeemInfo.RedemptionPageURL
//     }
//     else if (RedeemInfo.RedemptionPageURL.contains("/pages/CustomRedemptionPages/")) {
//       window.location = RedeemInfo.RedemptionPageURL
//     }
//     else {
//       window.location = `/Redeem/?RC=${redeemCode}`
//     }
//   }
//
//   if (children) {
//     return (
//       <div className={styles.customContent} style={style}>
//         {children}
//       </div>
//     )
//   }
//
//   const cartItems = !loading && cart && cart.Items && cart.Items.reduce((a, b) => a + b.Quantity, 0)
//
//   return (
//     <div className={styles.container}>
//       <div className={styles.leftContent}>
//         <div className={styles.mainMenu}>
//           <MainMenu />
//         </div>
//         {!!cartItems &&
//           <a href="/Purchase/ShoppingCart" className="nav-link">
//             <div className={styles.linkCart}>
//               <div className={styles.cartBadge}>
//                 {cartItems}
//               </div>
//               <i className="fa fa-shopping-cart" />
//               <span>Cart</span>
//             </div>
//           </a>
//         }
//         {loggedInUser ? (
//           <UncontrolledDropdown>
//             <DropdownToggle nav>
//               <div className={styles.linkLogin}>
//                 <i className="fa-regular fa-user"></i>
//                 <span className="p-2">Hi {loggedInUser.FirstName}!</span>
//               </div>
//             </DropdownToggle>
//             <DropdownMenu end>
//               <DropdownItem href="/Account/">
//                 <i className="fas fa-cog text-primary" />
//                 My Account
//               </DropdownItem>
//               <DropdownItem href="/Account/Orders">
//                 <i className="fas fa-cog text-primary" />
//                 My Orders
//               </DropdownItem>
//               <DropdownItem divider />
//               <DropdownItem>
//                 <NavLink onClick={logout}>
//                   <i className="fas fa-sign-out-alt text-primary" />
//                   Sign Out
//                 </NavLink>
//               </DropdownItem>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//         ) : (
//           <NavLink className={styles.linkLogin} onClick={() => setOpenLoginModal(true)}>
//             <i className="fa-regular fa-user"></i>Login
//           </NavLink>
//         )}
//         <LoginModal
//           isOpen={openLoginModal}
//           caption="Log In"
//           onClose={() => setOpenLoginModal(false)}
//           onSuccess={handleSuccessLogin}
//         />
//       </div>
//       <div className={styles.rightContent}>
//         <div className={styles.redeemContent}>
//           <Input
//             type="text"
//             placeholder="Enter Card Code"
//             className={styles.inputRedeemCode}
//             onKeyDown={event => event.key === "Enter" && handleRedeem()}
//             onChange={(event) => handleChangeRedeemCode(event)}
//           />
//           <div className={styles.btnRedeemContent}>
//             <button className={styles.btnRedeem} disabled={!codeValid} onClick={handleRedeem}>
//               Redeem Card
//             </button>
//             <a
//               className={styles.linkMultiRedeem}
//               href={`/Redeem/multi/?RC=${redeemCode}`}>
//               Redeem Multiple Codes
//             </a>
//           </div>
//         </div>
//         <a className={styles.btnGiveGift} href="/#GiveGiftCards">
//           Give Gift Card
//         </a>
//       </div>
//       {props.showFreeHolidayShipping && (
//         <Col
//           xs="12"
//           md="2"
//           className="text-center justify-content-center small text-uppercase">
//           <strong style={{ color: "#c44042" }}>FREE PRIORITY SHIPPING</strong>
//         </Col>
//       )}
//     </div>
//   )
// }


import React, { useState, useEffect } from "react"
import {
  Col,
  Input,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { redeemCodeValidation } from "../../../../Code/Utilities"
import MainMenu from "../../../Common/MainMenu"
import { useUserData } from "../../../../Context/user"
import { useCartData } from "../../../../Context/cart"
import { GetRedeemCodeInfo } from "../../../../Code/Data"
import LoginModal from "../../../Common/LoginModal"
import styles from "./styles.module.scss"

export default function RedBarFullSize(props) {
  const [redeemCode, setRedeemCode] = useState("")
  const [submitted, setSubmitted] = useState()
  const [openLoginModal, setOpenLoginModal] = useState()
  const [codeValid, setCodeValid] = useState(false)
  const { loggedInUser, logout, setUser } = useUserData()
  const { loading, cart } = useCartData()
  const { style, children } = props

  useEffect(() => {
    setCodeValid(!redeemCode)
    if (!redeemCode) return
    const fetchData = async () => {
      try {
        const RedeemInfo = await GetRedeemCodeInfo(redeemCode)
        return RedeemInfo
      } catch {
        return null
      }
    }

    const delayDebounceFn = setTimeout(() => {
      fetchData()
        .then((res) => {
          // Handle invalid format
          if (
            res &&
            res.ErrorMessage &&
            !redeemCodeValidation(redeemCode)
          ) {
            setCodeValid(false)
            if (!toast.isActive()) {
              toast.warn(
                "The Redemption Code entered is not a valid CharityChoice Redemption Code.",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 7000,
                },
              )
            }
            return
          }

          // Handle redeemed code
          else if (res && res.RedeemInfo && res.RedeemInfo.HasRedeemed) {
            setCodeValid(false)
            if (!toast.isActive()) {
              toast.warn("The code has already been redeemed.", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 7000,
              })
            }
            return
          }
          else if (res && !res.RedeemInfo.HasRedeemed) {
            setCodeValid(true)
            handleRedeem()
            return
          }
        })
        .catch((error) => {
          console.error("Error in setTimeout callback:", error)
        })
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [redeemCode])


  const handleChangeRedeemCode = ({ target }) => {
    const code = target.value
    setRedeemCode(target.value)
    // if (!redeemCodeValidation(code)) {
    //   setCodeValid(false)
    // }
  }

  const handleSuccessLogin = user => {
    setOpenLoginModal(false)
    setUser(user)
  }

  const handleRedeem = async () => {
    setSubmitted(true)
    if (!redeemCode && codeValid) {
      window.location = `/Redeem/?RC=${redeemCode}`
    }

    const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode)

    if (!Succeeded) {
      if (!toast.isActive()) {
        toast.error(ErrorMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 7000,
        })
      }
      return false
    }
    if (!RedeemInfo.HasCustomRedemptionPage) {
      window.location = `/Redeem/?RC=${redeemCode}`
    }
    else {
      window.location = `/Redeem/Custom/${loggedInUser.UserId}/?RC=${redeemCode}`
    }
  }

  if (children) {
    return (
      <div className={styles.customContent} style={style}>
        {children}
      </div>
    )
  }

  const cartItems = !loading && cart && cart.Items && cart.Items.reduce((a, b) => a + b.Quantity, 0)
  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <div className={styles.mainMenu}>
          <MainMenu />
        </div>
        {!!cartItems &&
          <a href="/Purchase/ShoppingCart" className="nav-link">
            <div className={styles.linkCart}>
              <div className={styles.cartBadge}>
                {cartItems}
              </div>
              <i className="fa fa-shopping-cart" />
              <span>Cart</span>
            </div>
          </a>
        }
        {loggedInUser ? (
          <UncontrolledDropdown>
            <DropdownToggle nav>
              <div className={styles.linkLogin}>
                <i className="fa-regular fa-user"></i>
                <span className="p-2">Hi {loggedInUser.FirstName}!</span>
              </div>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem href="/Account/">
                <i className="fas fa-cog text-primary" />
                My Account
              </DropdownItem>
              <DropdownItem href="/Account/Orders">
                <i className="fas fa-cog text-primary" />
                My Orders
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavLink onClick={logout}>
                  <i className="fas fa-sign-out-alt text-primary" />
                  Sign Out
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavLink className={styles.linkLogin} onClick={() => setOpenLoginModal(true)}>
            <i className="fa-regular fa-user"></i>Login
          </NavLink>
        )}
        <LoginModal
          isOpen={openLoginModal}
          caption="Log In"
          onClose={() => setOpenLoginModal(false)}
          onSuccess={handleSuccessLogin}
        />
      </div>
      <div className={styles.rightContent}>
        <div className={styles.redeemContent}>
          <Input
            type="text"
            placeholder="Enter Card Code"
            className={styles.inputRedeemCode}
            // invalid={!codeValid}
            value={redeemCode}
            onKeyDown={event => event.key === "Enter" && handleRedeem()}
            onChange={(event) => handleChangeRedeemCode(event)}
          />
          <div className={styles.btnRedeemContent}>

            <button className={styles.btnRedeem} disabled={!codeValid} onClick={handleRedeem}>
              Redeem Card
            </button>
            <Link            
              className={styles.linkMultiRedeem}
              to={`/Redeem/multi/?RC=${redeemCode}`}>
              Redeem Multiple Codes
            </Link>
          </div>
        </div>
        <a className={styles.btnGiveGift} href="/#GiveGiftCards">
          Give Gift Card
        </a>
      </div>
      {props.showFreeHolidayShipping && (
        <Col
          xs="12"
          md="2"
          className="text-center justify-content-center small text-uppercase">
          <strong style={{ color: "#c44042" }}>FREE PRIORITY SHIPPING</strong>
        </Col>
      )}
    </div>
  )
}
