import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import MainPage from "../Components/Shared/MainPage"
import { Card, CardBody, Row, Col } from "reactstrap"
import TitleAndMetaTags from "../Components/Common/TitleAndMetaTags"

export default function CorporateSolutions() {
  return (
    <Fragment>
      <TitleAndMetaTags pageName="Corporate Solutions" />
      <MainPage title="Corporate Solutions">
        <div className="px-1 mb-5 px-sm-5 container-fluid">
          <div className="text-center">
            <h5>Business Gifts, Events, Promotions, Employee Recognition</h5>
          </div>
          <div className="rounded px-3 px-sm-4 py-3 py-sm-5 text-center">
            <h3>
              'Giving Back' with charitable gifting for clients and employees.
            </h3>
            <h5>
              <em>Coping with challenging times...</em>
              <br />
              <br />
              Enhance Virtual Events
              <br />
              Keep in touch with your clients.
              <br />
              <em>Help charities when they need it the most.</em>
            </h5>
            <br />
            <h5>Speaks volumes about your company's values.</h5>
          </div>
          <Row>
            <Col lg={8} md={12}>
              <p>
                <strong>Corporate Customization Suite:</strong>
                <br />
                Our robust, online package provides outstanding branding, for
                large corporations and small businesses alike. We offer your
                business the best program for cause-affinity, proven to be
                effective in enhancing customer loyalty.
              </p>
              <p>
                <strong>
                  All corporate and business accounts receive these options:
                </strong>
              </p>
              <ul>
                <li>
                  <Link to="/Purchase/DigitalCards">Custom Digital eGifts</Link>
                  <div className="very-small">
                    Image Specs: Maximum size 792 X 450 pixels and maximum size
                    of 10 MB.
                  </div>
                </li>
                <li>
                  <Link to="/Purchase/PhysicalCards?type=3">
                    Custom Gift Cards
                  </Link>
                </li>
                <li>
                  <a href="/Purchase/PhysicalCards">Stock Physical Cards</a>
                </li>
                <li>
                  <a href="/Purchase/RedemptionCodes">
                    Unique Codes to Mail-merge
                  </a>
                </li>
                <li>
                  <Link to="/Account/CreateUserRedemptionPage/">
                    Customize your recipients Redemption Experience
                  </Link>
                </li>
                <li>
                  <a href="/Login?CA=1&ReturnPage=^custompageadmin^CreateCustomRedemptionPage">
                    Create a fully customized redemption landing page
                  </a>
                </li>
                <li>
                  <a href="/Login?CA=1&ReturnPage=^Corporate^MyCharities.aspx&ReturnResponse=CRP_NC">
                    Custom Charity List
                  </a>
                </li>
                <li>
                  <span className="text-primary">Pay-By-Check</span> (offered at checkout)
                  <div className="very-small">
                    Order goes through immediately and check is mailed later
                  </div>
                </li>
              </ul>
              <h3>Create your corporate account</h3>
              <p>
                <em>
                  The corporate customization suite offers a dramatic, engaging
                  method of delivering a meaningful message to clients about
                  your commitment to charitable giving.
                </em>
              </p>
              <p>
                Create eGifts with your unique graphic design or order bulk,
                custom gift cards.
                <br />
                You can even have affinity with specific charitable causes.
                Pre-select a group of charities to highlight on your branded,
                customized redemption page. FREE when using our online wizard.
              </p>
              <p>
                For Rewards and Incentive programs email: <a href="mailto:rewardsprograms@charity-choice.org">rewardsprograms@charity-choice.org</a>
              </p>
              <p>
                <a href="/Login?CA=1&ReturnPage=^Purchase">
                  <em>Create your account now...</em>
                </a>
              </p>
              <p className="very-small">
                <em>
                  CharityChoice gifts are 100% Tax Deductible!
                  <br />
                  Over 2500 charities for gift recipients to choose from.
                </em>
              </p>
            </Col>
            <Col lg={4} md={6} sm={10} xs={12}>
              <Card>
                <CardBody style={{ backgroundColor: "#e9ecef" }}>
                  <img
                    src="/Images/Quotes.gif"
                    alt="Southwest Airlines business clients response to Holiday charity donation gift cards."
                  />
                  <br />
                  <br />
                  We used this as a customer gift, and it was extremely well
                  received!
                  <br />
                  I love this website, we will definitely be using again!
                  <br />
                  <br />
                  <img
                    src="/Images/BrookeRodruigez.png"
                    alt="Business gift testimonial about CRM with CharityChoice charity cards."
                  />
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardBody style={{ backgroundColor: "#e9ecef" }}>
                  <img src="/Images/Quotes.gif" alt="Nasdaq utilizes charity gif cards for their company Christmas gifting." />
                  <br />
                  <br />
                  ...they are a big hit and I am happy to offer this
                  testimonial!
                  <br />
                  <br />
                  <img
                    src="/Images/nasdaq-laura-hilgren.png"
                    alt="Corporate client testimonial about CRM with our charity promotion."
                  />
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardBody style={{ backgroundColor: "#e9ecef" }}>
                  <img
                    src="/Images/IMA.png"
                    alt="CharityChoice donation gift cards is a sponsor of the IMA (Incentive Marketing Association) Summit."
                    style={{ maxWidth: '100%', height: 'auto', width: 'auto\\9' }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className="mt-4">
            <CardBody style={{ backgroundColor: "#e9ecef" }}>
              <img src="/Images/Quotes.gif" alt="Testimonial from a major research firm about charity gift effectiveness for good PR with giving back." />
              <br />
              <br />
              The CharityChoice program was an excellent way of giving back to
              those in need, with a marketing program that was very well
              received by our clients around the world.
              <br />
              Enabling customers to choose their charity makes it personal,
              which results in high engagement.
              <br />
              It's also very easy to implement. I highly recommend
              CharityChoice.
              <div className="small mt-5">
                <strong>Brad Mehl</strong>
                <br />
                Chief Marketing Officer
              </div>
              <img src="/Images/coleman.jpg" alt="COLEMAN executive endorses CharityChoice as effective marketing, demonstrating social responsibility." />
            </CardBody>
          </Card>
        </div>
      </MainPage>
    </Fragment>
  )
}
