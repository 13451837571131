import React, { useState } from "react"
import { Button } from "../../.."
import { useRedemptionData } from "../../../../Context/redemption"
import { ShoppingCartItemTypes } from "../../../../Code/Data"
import UploadCustomImage from "../../../Common/UploadCustomImage"
import styles from "./styles.module.scss"

function Step1({ onContinue }) {
  const { redemptionPageData, setRedemptionPageData } = useRedemptionData()
  const [logoFile, setLogoFile] = useState()
  const { HeaderImageURL } = redemptionPageData

  const handleUploadImage = file => {
    setRedemptionPageData({
      ...redemptionPageData,
      HeaderImageURL: file.secure_url,
    })
    setLogoFile(file)
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>Let’s customize your redemption page!</div>
      {HeaderImageURL && (
        <div className={styles.customLogo}>
          <img src={HeaderImageURL} alt="Custom Logo" />
        </div>
      )}
      <UploadCustomImage
        CardType={ShoppingCartItemTypes.RedemptionCodePurchase}
        showGui={false}
        onChange={handleUploadImage}>
        <Button className={styles.btnUpload} outline color="yellow">
          Upload Image
          <i className="fa-solid fa-image"></i>
        </Button>
      </UploadCustomImage>
      {logoFile && (
        <div className={styles.uploadDetail}>
          <div className={styles.fileLabel}>Uploaded File</div>
          <div className={styles.fileDetail}>
            <img src={logoFile.secure_url} alt="Custom Logo" />
            {logoFile.original_filename}.{logoFile.original_extension}
          </div>
        </div>
      )}
      <div className={styles.uploadLimitation}>
        <br />
        <strong>Recommended width and height</strong>
        <br />
        less than 500 pixels
        <div style={{ fontSize: '16px', margin: '10px' }}>
          <em>Please note</em>, you will be shown a preview of your redemption page on the next Step.
        </div>
      </div>
      <Button className={styles.btnContinue} onClick={onContinue}>
        Let's Continue
      </Button>
    </div>
  )
}

export default Step1
