import React, { useEffect, useRef, useState } from "react"
import { Label, Input, Alert } from "reactstrap"
import { useLocation, useHistory, Link } from "react-router-dom"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { useRedemptionData } from "../../../../Context/redemption"
import { usePurchaseData } from "../../../../Context/purchase"
import { RedeemCodes, AddToDonationCart } from "../../../../Code/Data"
import { useAppData } from "../../../../Context/appData"

import { redeemCodeValidation, runOnceAfterwards } from "../../../../Code/Utilities"
import Modal from "../../../Shared/Modal"
import { Button } from "../../../"
import AddToDonationCheckout from "../../../../Contents/ShoppingCart/AddToDonationCheckout"
import { srcCardSeasonGreetings } from "../../../../images"
import styles from "./styles.module.scss"

function Step1({ onContinue, setSuccess, codeAsEntered, charityId, multi, rc, codes, setCodes, loggedInUser }) {
  const [errorMessage, setErrorMessage] = useState("")
  const [code, setCode] = useState(codeAsEntered || "")
  const [loading, setLoading] = useState()
  const { fullCharityList } = useAppData()
  const { addToDonationData, setAddToDonationData } = usePurchaseData()
  const { amount } = addToDonationData
  const [codeValid, setCodeValid] = useState(true)//!!rc)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [urlCharityId, setUrlCharityId] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isActionModalOpen, setActionModalOpen] = useState(false)
  const [isMultiFieldDirty, setMultiFieldsDirty] = useState(!!codeAsEntered)
  const [directRedemption, setDirectRedemption] = useState(false)
  const [shoppingCart, setShoppingCart] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const inputRefs = useRef([])
  let selectedCharity
  const {
    redemptionData, getRedeemCodeInfo, setRedemptionData, setRedeemCodeInfo, redeemCodeInfo, redemptionPageInfo,
  } = useRedemptionData()
  const { search } = useLocation()
  const history = useHistory()
  const { WelcomeMessage, HasRedeemed, BrandEcard, LogoURL, ErrorMessage, HideAmount } = redeemCodeInfo || {}
  const debouncedSetCode = runOnceAfterwards((e) => setCode(e), 1500)
  const isMultiValid = codes?.length > 1

  useEffect(() => {
    isValid()
    if (charityId)
      setUrlCharityId(charityId)
    setIsInitialLoad(false)
  }, [])

  useEffect(() => {
    handleValidityChange()
  }, [errorMessage])

  // Handle redeemed code
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (HasRedeemed) {
        setCodeValid(false)
        toast.warn("The code has already been redeemed.", {
          position: toast.POSITION.TOP_CENTER, autoClose: 7000,
        })
      }
    }, 100)
    return () => clearTimeout(delayDebounceFn)
  }, [HasRedeemed])

  useEffect(() => {
    const doNow = async () => {
      if (!redeemCodeInfo) {
        const rci = await getRedeemCodeInfo(codeAsEntered)
        setErrorMessage(rci.ErrorMessage)
        setRedeemCodeInfo(rci)
      }
    }
    doNow()
  }, [paymentSuccess])

  useEffect(() => {
    multi ? setCodes(rc?.split(",")) : setCode(rc)
  }, [search])

  useEffect(() => {
    async function handleDirectRedemption() {
      if (directRedemption) {
        await RedeemDirectlyToCharity()
      }
    }

    handleDirectRedemption()
  }, [directRedemption])

  useEffect(() => {
    if (code) {
      const timer = setTimeout(async () => {
        if (redeemCodeValidation(code) && multi) {
          setCodes(prevCodes => [...prevCodes, code])
          setCode("")
        }
        else if (!multi) {
          await isValid()
        }
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [code, multi])

  const handleValidityChange = () => {
    const delayDebounceFn = setTimeout(() => {
      if (codeValid && agreeTerms) toast.dismiss("error")
      else if (codeValid && !agreeTerms) setErrorMessage("Please agree to terms and conditions")
      debugger
      if (errorMessage && !isInitialLoad &&
        (!codeValid || (!agreeTerms && codes?.length > 1)) &&
        (!!code || codes?.length > 0) &&
        !toast.isActive("error")) {
        toast.warn(errorMessage, {
          position: toast.POSITION.TOP_CENTER, autoClose: 7000,
          toastId: "error",
        })
      }
      else if (multi && codeValid && agreeTerms && !isMultiValid && !isInitialLoad) {
        toast.dismiss("error")
        toast.warn("This feature is for the redemption of multiple codes.",
          {
            position: toast.POSITION.TOP_CENTER, autoClose: 7000,
            toastId: "error",
          })
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }
  const RedeemDirectlyToCharity = async () => {
    selectedCharity = fullCharityList.find(({ CharityId }) => CharityId === redeemCodeInfo.CharityId)
    const payload = {
      RedeemCode: redeemCodeInfo,
      Charities: selectedCharity ? [{ CharityId: selectedCharity.CharityId }] : [],
      ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
    }
    payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    if (selectedCharity) {
      setRedemptionData({
        ...redemptionData, Charities: [selectedCharity],
      })
    }
    if (Succeeded !== undefined && Succeeded) {
      setSuccess(true)
    }
    else {
      if (!codeValid && !toast.isActive("error")) {
        toast.error(ErrorMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 7000,
        })
      }
    }
  }
  const handleChangeCode = async (e) => {
    setCode(e.target.value.toUpperCase())
    await isValid()
  }

  const handleChangeAmount = event => {
    setAddToDonationData({
      ...addToDonationData, amount: event || 0,
    })
  }
  const handleAddCode = async () => {
    console.log("rci", redeemCodeInfo)
    const result = await getRedeemCodeInfo(code, loggedInUser?.UserId)
    if (redeemCodeValidation(code) && result?.Succeeded && codeValid) {
      var nonEmptyCodes = codes.filter((value) => value.trim() !== "")
      nonEmptyCodes.push(code)
      setCodes([...nonEmptyCodes])
      setCode("")
    }
    else {
      var nonEmptyCodes = codes.filter((value) => value.trim() !== "")
      nonEmptyCodes.push(code)
      await isValid(nonEmptyCodes)
      handleValidityChange()
    }
  }
  const handleChangeMultiCode = index => async e => {
    if (!!e && !!codes) setMultiFieldsDirty(true)
    if (!e.target.value) {
      const updatedCodes = codes.filter((_, i) => i !== index)
      setCodes(updatedCodes)
      if (index > 0) {
        inputRefs.current[index - 1].focus()
      }
      else if (inputRefs.current[0]) {
        inputRefs.current[0].focus()
      }
      await isValid(updatedCodes)
    }
    else {
      //Editing existing code
      const inputValue = e.target.value.toUpperCase()
      const updatedCodes = [...codes]
      updatedCodes[index] = inputValue
      setCodes(updatedCodes)
      await isValid(updatedCodes)
      if (index === codes.length) {
        clearInputById(`${index}`)
      }
    }
    setCode(null)
  }
  const isValid = async (codesArr = null) => {
    debugger
    if (!codesArr) codesArr = codes
    if (multi) {
      const uniqueValues = new Set(codesArr)
      let valid = codesArr.length === uniqueValues.size
      if (!valid) {
        setCodeValid(false)
        setErrorMessage("Repeated use of a code detected!")
      }
      else {
        for (let i = 0; i < codesArr.length; i++) {
          const rci = await getRedeemCodeInfo(codesArr[i])//.toUpperCase())
          if (!rci?.Succeeded && !isInitialLoad) {
            valid = false
            let errorMessage = (rci?.ErrorMessage)// || `${codesArr[i]} is not a valid CharityChoice Redeem Code`)
            setErrorMessage(errorMessage)
            break
          }
          else {
            toast.dismiss("error")
            setErrorMessage(null)
          }
        }
        setCodeValid(valid)
      }
    }
    else {
      const rci = await getRedeemCodeInfo(code, loggedInUser?.UserId)
      if (rci?.HasCharityId) {
        setCodeValid(true)
        setDirectRedemption(true)
      }
      if (rci?.UserRedemptionPageInfo) {
        history.push(`/Redeem/Custom/${rci.UserRedemptionPageInfo.UserId}/?RC=${codeAsEntered}`)
      }
      if ((!rci?.Succeeded || !redeemCodeValidation(code)) && !isInitialLoad) {
        setCodeValid(false)
        setErrorMessage(rci.ErrorMessage)// || `${code} is not a valid CharityChoice Redeem Code`)
      }
      else {
        toast.dismiss("error")
        setCodeValid(true)
        setErrorMessage("")
      }
      return codeValid
    }
  }
  const handleAddMultiCode = async e => {
    const multiCodes = e.target.value.split(/\s+/).filter(char => char.length > 0)
    if (multiCodes.length > 1) {
      setCodes(prevCodes => {
        const newCodes = multiCodes.map(char => char.toUpperCase())
        return [...prevCodes, ...newCodes.filter(code => !prevCodes.includes(code))]
      })
      await isValid()
      setCode("")
      return
    }
    else {
      if (!codes) {
        setCodes(multiCodes[0]?.toUpperCase())
      }
      debouncedSetCode(e.target.value.toUpperCase())
    }
  }
  const handleChangeData = e => {
    setRedemptionData({
      ...redemptionData, [e.target.name]: e.target.value,
    })
  }
  const handleToggleTerms = () => {
    setAgreeTerms(!agreeTerms)
  }
  const handleContinue = async () => {
    if (amount > 0 && !paymentSuccess) {
      if (!isActionModalOpen) {
        setActionModalOpen(true)
        return
      }
      else {
        setActionModalOpen(false)
        setAddToDonationData({
          ...addToDonationData, amount: 0,
        })
      }
    }
    setRedemptionData({
      ...redemptionData, code: multi ? codes.join(",") : code,
    })
    if (redeemCodeInfo?.HasCharityId || urlCharityId) {
      const selectedCharity = fullCharityList.find(({ CharityId }) => CharityId === (redeemCodeInfo.CharityId || charityId))
      const payload = {
        RedeemCode: redeemCodeInfo.RedeemCode,
        Charities: selectedCharity ? [{ CharityId: selectedCharity.CharityId }] : [],
        ShareRedeemerInfo: redeemCodeInfo?.ShowDonor,
      }
      payload.Charities[0].Amount = +redeemCodeInfo.AmountToRedeem
      const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
      if (Succeeded) {
        setSuccess(true)
      }
      else {
        setErrorMessage(ErrorMessage)
        setCodeValid(false)
      }
      return
    }
    onContinue()
  }
  const clearInputById = (id) => {
    const inputElement = document.getElementById(id)

    if (inputElement) {
      inputElement.value = ""
    }
  }

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true)
    setModalOpen(false)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const onPay = async () => {
    const result = await AddToDonationCart(amount)
    if (result?.Succeeded) {
      setShoppingCart(result.ShoppingCart.Items[0])
      setModalOpen(true)
    }
    setActionModalOpen(false)
  }
  const renderRedeemInput = () => {
    const logoImage = ((BrandEcard && LogoURL) ? <div className={styles.title}>
      <img className={styles.imgLogo} src={LogoURL} alt="Corporate Logo" />
    </div> : null)

    const title = redeemCodeInfo?.AmountToRedeem && HideAmount === false
      ? `You have $${paymentSuccess ? (parseFloat(redeemCodeInfo.AmountToRedeem) + parseFloat(amount)) : redeemCodeInfo.AmountToRedeem} to redeem!`
      : "Let's redeem your Charity Gift!"
    return (<>
      {logoImage}
      {paymentSuccess &&
        <span
          className={styles.description}>Thank you for adding ${amount || redeemCodeInfo?.AddedToDonationAmount} to this donation.</span>
      }
      <div className={styles.title}>{title}</div>
      <img
        className={styles.imgCard}
        src={redeemCodeInfo?.ImageUrl || srcCardSeasonGreetings}
        alt=""
      />
      <div className={styles.redemptionDetail}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputCode}
            name="code"
            maxLength={10}
            value={code || ""}
            onChange={e => setCode(e.target.value.toUpperCase())}
            onKeyUp={e => (handleChangeCode(e))}
            placeholder="Redeem Code"
          />
          {code &&
            <div className={styles.linkChangeCode}
                 onClick={e => runOnceAfterwards(handleChangeCode(e.target.value), 1000)}>Change Code</div>
          }
        </div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputEmail}
            style={{ fontSize: redemptionData?.email ? "130%" : "32px" }}
            name="email"
            maxLength={8}
            value={redemptionData?.email || ""}
            placeholder="Email Address"
            onChange={handleChangeData}
          />
          <div className={styles.lblEmail}>
            Optional: To receive a confirmation
          </div>
        </div>
      </div>
    </>)
  }

  const renderMultiRedeemInput = () => {
    return (
      <>
        <div className={styles.title}>Enter Your Redemption Codes</div>
        <div className={styles.subTitle}>For auto formatting, paste multiple codes in a single box.</div>
        {codes.map((code, index) => (
          <div key={index} className={styles.multiInputWrapper}>
            <input
              ref={el => inputRefs.current[index] = el}
              minLength={4}
              maxLength={9}
              className={styles.multiInputCode}
              name={`code-${index}`}
              value={code}
              onChange={handleChangeMultiCode(index)}
            />
          </div>
        ))}
        <div className={styles.multiInputWrapper}>
          <input
            className={styles.multiInputCode}
            name="code"
            placeholder="Input Code"
            value={code}
            onKeyUp={handleAddCode}
            onChange={e => {
              const value = e.target.value
              if (/\s/.test(value) || value.includes("\n")) {
                handleAddMultiCode(e)
              }
              else setCode(value.toUpperCase())
            }}
          />
          <i className="fa fa-plus-circle" onClick={handleAddCode} />
        </div>
      </>
    )
  }
  return (
    <div className={styles.container}>
      <Modal
        isOpen={isActionModalOpen && !paymentSuccess}
        onClose={() => setActionModalOpen(false)}
        contentLabel="Action dialog"
      >
        <p className="fs-4">Did you change your mind about adding ${amount} to this donation?</p>
        <button className={styles.yesBtn} onClick={() => handleContinue()}>Yes, Continue</button>
        <button className={styles.noBtn} onClick={() => onPay()}>No, Add ${amount} Now</button>
      </Modal>

      <Modal
        isOpen={isModalOpen && !paymentSuccess}
        onClose={() => setModalOpen(false)}
        contentLabel="Action dialog"
      >
        <AddToDonationCheckout item={shoppingCart} amount={amount} onPaymentSuccess={handlePaymentSuccess}
                               code={code} />
      </Modal>
      <div className={styles.content}>
        {multi ? renderMultiRedeemInput() : renderRedeemInput()}
        <Label check className={styles.termsWrapper}>
          <Input
            className={styles.termsRadio}
            type="radio"
            checked={agreeTerms === true}
            onClick={handleToggleTerms}
          />{" "}
          <span className={styles.lblTerms}>
            I affirm that I am the gift recipient or that I received this code
            in a rewards program by me redeeming my points. View our &nbsp;
            <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
          </span>
        </Label>
        <Button
          className={`${styles.btnContinue} m-3`}
          disabled={!codeValid || !agreeTerms || (multi && !isMultiValid)}
          onClick={handleContinue}>
          Continue
        </Button>
        {codeValid && !multi && redeemCodeInfo?.HasCharityId !== true && !paymentSuccess &&
          <div className={styles.divAddToDonation}>
            <div>
              To add to this donation, enter an amount
            </div>
            <div
              title={"When you designate your charity gift, you may add your own funds to increase the benefit for your favorite charities. The total sum of your charity gift and whatever you have added, will be divided equally amongst the chosen charities. You will receive an emailed tax receipt for the additional funds."}>
              <CurrencyInput className={styles.amountInput} onValueChange={handleChangeAmount}
                             allowNegativeValue={false} prefix={"$"} value={amount} />
            </div>
            <div>
              {amount > 0 &&
                <div className={styles.addToDonationBtn} loading={loading} onClick={() => onPay()}> Add
                  ${amount} Now </div>
              }
            </div>
          </div>}
      </div>
    </div>)
}

export default Step1