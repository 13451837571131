import React, { useState } from "react"
import { Alert } from "reactstrap"
import { srcDefaultImage } from "../../../../images"
import { useRedemptionData } from "../../../../Context/redemption"
import CharitySelector from "../../../Common/CharitySelector"
import { Button } from "../../.."
import styles from "./styles.module.scss"
import { SetRedemptionPage } from "../../../../Code/Data"
import { useUserData } from "../../../../Context/user"

function Step4({ onBack, onSuccess, isEditMode, setEditMode }) {
  const { redemptionPageData, setRedemptionPageData } = useRedemptionData()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const { loggedInUser } = useUserData()
  const { HeaderImageURL, Caption, MainColor, AccentColor, Charities } =
    redemptionPageData
  const buttonText = isEditMode ? "Save Changes" : "Confirm Selections"
  const [selCharityList, setSelCharityList] = useState([])
  const handlePreselectedCharity = async charity => {
    setRedemptionPageData({
      ...redemptionPageData,
      Charities:
        Charities.length < 3 && !Charities.includes(charity.CharityId)
          ? [...Charities, charity.CharityId]
          : Charities,
    })

    setSelCharityList(prevSelCharityList => {
      if (prevSelCharityList.length < 3) {
        return [...prevSelCharityList, charity];
      }
      return prevSelCharityList;
    });
  }

  const handleRemoveCharity = charityId => () => {
    const charityIndex = Charities.indexOf(charityId);
    Charities.splice(charityIndex, 1);
    const updatedSelCharityList = selCharityList.filter(
      charity => charity.CharityId !== charityId
    )
    setSelCharityList(updatedSelCharityList)
  }

  const handleComplete = async () => {
    setEditMode(false)
    const payload = {
      Charities,
      HeaderImageURL,
      Caption,
      MainColor,
      AccentColor,
    }
    setLoading(true)
    const { Succeeded, ErrorMessage } = await SetRedemptionPage(
      loggedInUser?.UserId,
      payload
    )
    setLoading(false)
    if (Succeeded) {
      onSuccess()
    } else {
      setError(ErrorMessage)
    }
  }

  return (
    <div className={styles.container} id="step4">
      <div className={styles.content}>
        {Charities && (
          <CharitySelector
            charities={Charities || []}
            selCharityList={selCharityList}
            setSelCharityList={setSelCharityList}
            onUpdate={handlePreselectedCharity}
            fromRedemption={false}
          />
        )}
      </div>
      <div className={styles.charityContent}>
        <Alert color="danger" isOpen={!!error} toggle={() => setError()}>
          <div className={styles.errorWrapper}>{error}</div>
        </Alert>
        <div className={styles.lblCharity}>Your selected charities</div>
        
        {selCharityList &&
          selCharityList.map(
            ({ CharityId, LogoUrl, CharityName, CategoryName }, index) => (
              <div key={index} className={styles.preselectedCharity}>
                <div className={styles.itemImage}>
                  <img src={LogoUrl || srcDefaultImage} alt={CharityName} />
                  <div
                    className={styles.btnRemove}
                    onClick={handleRemoveCharity(CharityId)}>
                    <i className="fa-solid fa-xmark" />
                  </div>
                </div>
                <div className={styles.itemDetail}>
                  <div className={styles.itemName}>{CharityName}</div>
                  <div className={styles.itemDesc}>{CategoryName}</div>
                </div>
              </div>
            )
          )}
        <div className={styles.actions}>
          <Button
            className={styles.btnContinue}
            disabled={!Charities}
            loading={loading}
            onClick={handleComplete}>
            {buttonText}
          </Button>
          <button className={styles.btnStepBack} onClick={onBack}>
            Go Back
            <i className="fa fa-undo" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Step4
