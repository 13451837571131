import React, { useEffect, useState } from "react"
import { Button, Modal } from "reactstrap"
import LoadingSpinner from "../LoadingSpinner"
import styles from "./styles.module.scss"
import { GetCharityInfo } from "../../../Code/Data"

export default function CharityDetailModal({ charity, onClose, onSelect }) {
  const [loading, setLoading] = useState(true)
  const [charityDetail, setCharityDetail] = useState()

  useEffect(() => {
    async function loadCharityDetail() {
      setLoading(true)
      setCharityDetail()

      if (charity) {
        const charityInfo = await GetCharityInfo(charity.CharityId)
        setCharityDetail(charityInfo)
      }
      setLoading(false)
    }

    charity && loadCharityDetail()
  }, [charity])

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }

    const {
      CharityName,
      CategoryName,
      Locations,
      ShortDescription,
      LongDescription,
      LogoUrl,
      Url,
      IsLocal
    } = charityDetail

    return (
      <>
        <div className={styles.charityName}>
          {CharityName}

          {!!onSelect && (
            <Button
              className={styles.btnSelect}
              outline
              color="primary"
              size="sm"
              onClick={() => onSelect(charity)}>
              Select Charity
            </Button>
          )}
        </div>
        <img
          className={styles.img}
          src={LogoUrl}
          onError={e => {
            e.target.onerror = null
            e.target.src = "images/default.png"
          }}
          alt={CharityName}
        />

        {Url && (
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={Url}>
            <i className="fa-solid fa-globe" />
            {Url}
          </a>
        )}
        {CategoryName && (
          <div className={styles.detailItem}>
            <b>Charity Category:</b> {CategoryName}
          </div>
        )}
        {IsLocal && Locations && (
          <div className={styles.detailItem}>
            <b>Location:</b> {Locations.join(", ")}
          </div>
        )}
        <div className={styles.description}>{ShortDescription}</div>
        {LongDescription && (
          <div className={styles.description}>{LongDescription}</div>
        )}
      </>
    )
  }

  return (
    <Modal
      contentClassName={styles.modalContent}
      centered
      size="lg"
      isOpen={charity}
      toggle={onClose}>
      <div className={styles.btnClose} onClick={onClose}>
        <i className="fa-solid fa-xmark" />
      </div>
      {renderContent()}
    </Modal>
  )
}
