import React, { useEffect, useState } from "react"
import { Alert } from "reactstrap"
import {
  PaymentTypes,
  PreCheckoutApi,
  AddToDonation,
} from "../../../Code/Data"
import { useUserData } from "../../../Context/user"
import { useAppData } from "../../../Context/appData"
import {
  scrollToElement,
  registerAnalyticsPurchase,
} from "../../../Code/Utilities"
import PayPalSDK from "../../../Components/MiscComponents/PayPalSDK"
import { Button } from "../../../Components"
import styles from "./styles.module.scss"
import { Success } from "../../../Components/RedeemCodes"
import LoadingSpinner from "../../../Components/Common/LoadingSpinner"
import LoginModal from "../../../Components/Common/LoginModal"

function AddToDonationCheckout({ item, amount, onFail, code, onPaymentSuccess }) {
  const [canUseCorporateCheck, setCanUseCorporateCheck] = useState(false)
  const [errMsg, setErrMsg] = useState()
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openCorporateCheckModal, setOpenCorporateCheckModal] = useState(false)
  const { PayPalJDKClientId } = useAppData()
  let succeeded = false
  const [purchaseId, setPurchaseId] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [user, setUser] = useState(null)
  const [test, setTest] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    loading: loadingUser,
    loggedInUser,
    logout,
    refreshUser,
  } = useUserData()

  useEffect(() => {
    //rerender paypal buttons
  }, [loggedInUser])
  const handleCorporateCheck = () => {
    setOpenCorporateCheckModal(false)
    preCheckOut(PaymentTypes.Corporate)
  }

  const preCheckOut = async selectedCheckoutType => {
    if (!loggedInUser) {
      setOpenLoginModal(true)
      return false
    }

    const precheckOutRequest = {
      IsPayPalExpress: selectedCheckoutType === PaymentTypes.Paypal,
      IsCorporateCheck: selectedCheckoutType === PaymentTypes.Corporate,
      IsPayPalSDKCheckout: selectedCheckoutType === PaymentTypes.PayPalSDK,
      SendECardReminder: false,
      CategoryForShowOnly: null,
      CategoryForShowAlso: null,
      CustomCharityListId: null,
      CustomCharityList: null,
      PreSelectedCharityId: null,
      ExpressCheckoutUrl: `https://${window.location.host}/Purchase/PayPalCheckout`,
      ExpressCancelUrl: `https://${window.location.host}/Purchase/ShoppingCart`,
    }

    const { Succeeded, RedirectURL, ErrorMessage } = await PreCheckoutApi(precheckOutRequest)

    if (Succeeded) {
      setLoading(false)
    }
    else {
      setErrMsg(ErrorMessage)
      setTimeout(() => scrollToElement(".alert-danger", true, 0), 500)
      onFail(ErrorMessage)
    }
  }

  const preCheckoutSdk = async (data, actions) => {
    setLoading(true)
    await preCheckOut(PaymentTypes.PayPalSDK)
  }

  const payPalSDKOrderSuccess = async details => {
    if (!item) {
      return setErrMsg("Cart does not exist")
    }
    setErrMsg()
    const payload = {
      RedeemCode: code,
      Amount: parseInt(amount),
      PaymentInfo: details,
    }

    const { Succeeded, AddToDonationId, ErrorMessage } = await AddToDonation(payload)
    succeeded = Succeeded
    setErrorMessage(ErrorMessage)
    setPurchaseId(AddToDonationId)
    if (ErrorMessage) onFail(ErrorMessage)
    let mappedItem = [
      item && {
        item_id: item.PaidItemId,
        item_name: item.Description,
        discount: (!!item.DiscountPercent ? ((item.DiscountPercent / 100) * item.TotalForCards) : 0),
        index: item.ItemIndex,
        price: item.Denomination,
        quantity: 1,
      },
    ].filter(Boolean)

    registerAnalyticsPurchase({
      currency: "USD",
      transaction_id: purchaseId,
      value: item.Denomination,
      items: mappedItem,
    })
    setShowSuccess(true)
    onPaymentSuccess()
  }
  const handleSuccessLogin = async (user) => {
    setOpenLoginModal(false)
    const result = await refreshUser()
    if (!loggedInUser) setUser(user)
    debugger
    console.log("user", item, showSuccess)
  }

  return (
    <div className={styles.container}>
      {loading &&
        <><h3>Processing your payment...</h3>
          <LoadingSpinner />
        </>}
      {item && !showSuccess && <div className={styles.content}>
        {errMsg && <Alert color="danger">{errMsg}</Alert>}
        <div className={styles.cartDetail}>
          {`Add $${amount}`}
        </div>
        {!loggedInUser && (<Button
          className={styles.btnLogin}
          outline
          color="red"
          onClick={() => setOpenLoginModal(true)}>
          Login & Create Account
        </Button>)}
        {!!loggedInUser &&
          <div className={styles.checkoutButtons}>
            <PayPalSDK
              clientId={PayPalJDKClientId}
              donationId={item?.PaidItemId}
              showMessages
              addToDonationAmount={amount}
              showButtons={true}
              options={{ "enable-funding": "paylater,venmo" }}
              onClick={preCheckoutSdk}
              amount={item.Denomination}
              debug={process.env.REACT_APP_DEV_BUILD === "true"}
              shippingPreference="NO_SHIPPING"
              style={{
                layout: "vertical", color: "gold", shape: "pill", label: "pay", tagline: false,
              }}
              onInit={(data, actions) => loggedInUser || user ? actions.enable() : actions.disable()}
              onSuccess={(details) => {
                const updatedDetails = { ...details, isAddedDonation: true }
                payPalSDKOrderSuccess(updatedDetails)
              }}
            />
          </div>
        }
        {canUseCorporateCheck && loggedInUser && (<Button
          id="btnPayByCorporateCheck"
          className={styles.btnCorporateCheck}
          onClick={() => setOpenCorporateCheckModal(true)}
          color="primary"
          block={true}>
          Pay by Corporate Check
        </Button>)}
      </div>}
      <LoginModal
        isOpen={openLoginModal}
        caption="Please Log In to Check Out!"
        onClose={() => setOpenLoginModal(false)}
        onSuccess={handleSuccessLogin}
      />
    </div>
  )
}

export default AddToDonationCheckout
