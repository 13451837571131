import React from "react"
import { srcDefaultImage } from "../../../images"
import styles from "./styles.module.scss"

function UserRedemptionPageHeader({ headerImageURL, welcomeMessage, caption, mainColor }) {
  if (headerImageURL || caption) {
    return (
      <div className={styles.header}>
        {headerImageURL && (
          <div className={styles.logo}>
            {headerImageURL && (
              <img
                src={headerImageURL || srcDefaultImage}
                alt={welcomeMessage}
              />
            )}
          </div>
        )}
        {caption && (
          <div className={styles.caption} style={{ color: mainColor }}>
            {caption}
          </div>
        )}
      </div>
    )
  }
  return null;
}

export default UserRedemptionPageHeader
