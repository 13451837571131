import React, { useEffect, useState } from "react"
import { Label, Input, Alert } from "reactstrap"
import { useLocation, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useRedemptionData } from "../../../../Context/redemption"
import { RedeemCodes } from "../../../../Code/Data"
import { useAppData } from "../../../../Context/appData"
import { redeemCodeValidation, runOnceAfterwards } from "../../../../Code/Utilities"
import { Button } from "../../../"
import styles from "./styles.module.scss"
import { AutoHeightTextarea } from "../../../Common/AutoHeightTextArea"

function Step1Multi({ onContinue, setSuccess, codeAsEntered, charityId, rc, codes = [], setCodes, loggedInUser }) {
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState()
  const [newCodes, setNewCodes] = useState([])
  const { fullCharityList } = useAppData()
  const [codeValid, setCodeValid] = useState(!!rc)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [isMultiFieldDirty, setMultiFieldsDirty] = useState(!!codeAsEntered)
  const [directRedemption, setDirectRedemption] = useState(false)
  let selectedCharity
  const {
    redemptionData, getRedeemCodeInfo, setRedemptionData, setRedeemCodeInfo, redeemCodeInfo, redemptionPageInfo,
  } = useRedemptionData()
  const { search } = useLocation()
  const history = useHistory()
  const { WelcomeMessage, HasRedeemed, BrandEcard, LogoURL, ErrorMessage, HideAmount } = redeemCodeInfo || {}

  useEffect(() => {
    const codesToAdd = rc?.split(",")
    if (codesToAdd && codesToAdd.length > 0)
      if (codesToAdd && codesToAdd.length > 0)
        setCodes(codesToAdd)
  }, [search])

  const handleNewCodes = async () => {
    const list = newCodes.split('\n')

    if (!list.length) {
      setErrorMessages(["Please enter your codes"])
    }
    else if (list.length === 1) {
      history.push(`/Redeem/?RC=${codes[0]}`)
    }
    else {
      const newErrorMessages = [];
      const addedCodes = [];
      list.forEach(async c => {
        const newCode = c.trim().toUpperCase();
        if (!newCode) return;
        if (!redeemCodeValidation(newCode)) {
          newErrorMessages.push(`${newCode} is not a valid CharityChoice redeem code`);
          return;
        }
        const result = await getRedeemCodeInfo(newCode, loggedInUser?.UserId)
        if (!result.Succeeded) {
          newErrorMessages.push(`${newCode} is not a valid CharityChoice redeem code`);
          return;
        }
        addedCodes.push(newCode)
        return;
      })
      if (addedCodes.length && newErrorMessages.length === 0) {
        const unique = [...new Set([...codes, ...addedCodes])];
        setCodes(unique)
      }
      else if (newErrorMessages.length) {
        setErrorMessages([...errorMessages, newErrorMessages])
      }
    }
  }

  const handleContinue = async () => {
    setRedemptionData({
      ...redemptionData, code: codes.join(",")
    })
    onContinue()
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>Enter Your Redemption Codes</div>
        <div className={styles.multiInputWrapper}>
          <AutoHeightTextarea
            className={styles.multiInputCode}
            name="code"
            placeholder="Input Codes"
            value={newCodes}
            rows={3}
            onChange={(e) => setNewCodes(e.target.value)}
          />
          <Button
            className={`${styles.btnContinue} m-3`}
            onClick={handleNewCodes}>
            Add these Codes
          </Button>
        </div>
        <Label check className={styles.termsWrapper}>
          <Input
            className={styles.termsRadio}
            type="radio"
            checked={agreeTerms === true}
            onChange={() => setAgreeTerms(!agreeTerms)}
          />{" "}
          <span className={styles.lblTerms}>
            I affirm that I am the gift recipient or that I received this code
            in a rewards program by me redeeming my points. View our &nbsp;
            <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
          </span>
        </Label>
        {codes.length > 0
          ? (errorMessages && errorMessages.length > 0
            ? <ul>{errorMessages?.map((em, index) => <li key={index}>{em}</li>)}</ul>
            : <>Please Enter Redemption Codes</>)
          : <Button
            className={`${styles.btnContinue} m-3`}
            onClick={handleContinue}>
            Continue
          </Button>
        }
      </div>
    </div>)
}

export default Step1Multi

