import React, { createContext, useContext, useState } from "react"
import { DeliveryMethods, PhysicalCardTypes } from "../Code/Data"

const PurchaseContext = createContext()

export const PurchaseProvider = ({ children }) => {
  const [digitalCardData, setDigitalCardData] = useState({
    deliveryMethod: null,
    sendDate: undefined, //null = send now, undefined = not yet set.
    customImage: null,
    // amount: 0,
    // card: {
    //   CardId: 313,
    // },
    recipients: [
      {
        FullName: "",
        Email: "",
        Phone: "",
      },
    ],
    greeting: "A gift for you!",
    message: "Redeem for the cause closest to your heart... enjoy!",
  })
  const [physicalCardData, setPhysicalCardData] = useState({
    deliveryMethod: PhysicalCardTypes.ToMe,
    shippingInfo: {
      ShippingFirstName: "",
      ShippingLastName: "",
      Email: "",
      ShippingPhone: "",
      ShippingAddress1: "",
      ShippingAddress2: "",
      ShippingCity: "",
      ShippingZip: "",
      ShippingState: "",
    },

  })
  const [physicalCards, setPhysicalCards] = useState([])
  const [addToDonationData, setAddToDonationData] = useState({amount: null})
  const [honorCardData, setHonorCardData] = useState({
    deliveryMethod: DeliveryMethods.Email,
    amount: 0,
    recipients: [
      {
        name: "",
        email: "",
        phone: "",
      },
    ],
    greeting: "",
    message: "",
  })

  const [merchantCardData, setMerchantCardData] = useState({
    retailer: {
      RetailerId: 254,
      AcceptsEcode: true,
      DisplayName: "1800PetSupplies.com",
      IsCreditCard: false,
      ECodeResellPercent: 65,
      PhysicalResellPercent: 70,
      Minimum: 1,
      Maximum: 500,
      Validation: [
        {
          CardNumberLength: 9,
          PinLength: 0,
        },
        {
          CardNumberLength: 19,
          PinLength: 4,
        },
      ],
    },
    Cards: [],
    Charities: [],
  })

  return (
    <PurchaseContext.Provider
      value={{
        digitalCardData,
        setDigitalCardData,
        physicalCardData,
        setPhysicalCardData,
        physicalCards,
        setPhysicalCards,
        honorCardData,
        addToDonationData,
        setAddToDonationData,
        setHonorCardData,
        merchantCardData,
        setMerchantCardData
      }}>
      {children}
    </PurchaseContext.Provider>
  )
}

export const usePurchaseData = () => useContext(PurchaseContext)
